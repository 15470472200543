import React from 'react'
import { useRouter } from '../../components'
import { SiteLink } from '../../components'
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    {
      url: '/masterclass-colouring',
      title: 'MASTERCLASS',
      icon: 'book-icon.png',
      iconHover: 'book-icon.png',
    },
    {
      url: '/the-world-of-gemstones',
      title: 'GEMSTONES',
      icon: 'gemstones-icon.png',
      iconHover: 'gemstones-icon.png',
    },
    {
      url: '/shivani-essentials',
      title: 'SHIVANI',
      icon: 'oils-icon.png',
      iconHover: 'oils-icon.png',
    },
    {
      url: '/grace-guitars',
      title: 'GUITARS',
      icon: 'guitar-icon-web.png',
      iconHover: 'guitar-icon-web.png',
    },
    {
      url: '/greg-c-grace-photography',
      title: 'PHOTOGRAPHY',
      icon: 'camera-icon.png',
      iconHover: 'camera-icon.png',
    },
  ]

  //const path = usePath();
  const { pathname } = useRouter()

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase()
  }

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className="icon">
          <img
            className="normal"
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className="selected"
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ))

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  )
}
