/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { tileStyle } from './productTileStyle';

export function ProductTile({ product, mainCategory = '' }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productSlug}`;

  return (
    <div css={[s.tile]}>
      <SiteLink css={s.tileLink} to={productUrl} className={`${mainCategory}`}>
        <div css={s.prdImage} >
          <div css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        
        <div css={s.name} className={`${mainCategory}`}>
          {product.productName}
        </div>
        
        <div css={s.shortDes} className={`${mainCategory}`}>
          {product.shortDesc}
        </div>
        
        <div css={s.price} className={`${mainCategory}`}>
          { 
            product.productStatus !== 0 ? product.productStatusText :
            product.priceTextOveriding ? product.priceTextOveriding : 'AU' + utils.money.formatMoney(product.priceIncTax)
          }
        </div>

      </SiteLink>
    </div>
  )
}