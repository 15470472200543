/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./cartStyle";
import React from "react";

//import { SiteLink } from '../../../components';

import env from "../../../env";
import { useGet, usePost } from "../../../components";
import { useForm } from "../../../form";
import { Loading, ErrorPopup } from "../../../components";

import { useStep } from "./useStep";
import { StepNav } from "../cartStep/stepNav";

import { OrderItems } from "../cartOrderItems/orderItems";
import { getOrderItemFormData } from "../cartOrderItems/orderItemFormData";
import { Shipping } from "../cartShipping/shipping";
import { getShippingFormData } from "../cartShipping/shippingFormData";
import { Pay } from "../cartPayment/pay";
import utils from "../../../utils";

export function Cart() {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [cart, setCart] = React.useState(null);


  console.log("cart", cart);

  const get = useGet();

  React.useEffect(() => {
    get.send(env.apiBase + "/api/cart/getCart/" + env.getDevCartId());
    setSendingStatus("pending");
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  if (sendingStatus === "pending") {
    setCart(get.response);
    setSendingStatus(null);
  }

  if (!cart || cart.cartInfo.totalQuantity === 0) {
    return <EmptyCart />;
  }
  return <CartView cart={cart} setCart={setCart} />;
}

function CartView({ cart, setCart }) {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [goNext, setGoNext] = React.useState(false);
  //const defaultCategory = cart.defaultCategory ? cart.defaultCategory.categoryId : '';
  const post = usePost();
  const stepInfo = useStep([
    { id: "orderItems", title: "Cart" },
    { id: "shipping", title: "Your Details" },
    { id: "payment", title: "Pay" },
  ]);
  const { steps, currentStep, lastStep, moveNext, markAsFilled } = stepInfo;

  const orderItemForm = useForm(getOrderItemFormData(cart), {
    onControlChanged: orderItemChanged,
  });
  const shippingForm = useForm(getShippingFormData(cart), {
    onControlChanged: orderItemChanged,
  });

  function orderItemChanged(e, control) {

    if (control && control.name === "quantity") {
      const index = orderItemForm
        .findControl("items")
        .findIndexById(control.parent.id);
      const orderItem = cart.orderItems[index];
      const value = {
        qty: control.value,
        orderItemId: orderItem.orderItemId,
        cartId: env.getDevCartId(),
      };
      post.send(env.apiBase + "/api/cart/updateQty", value);
      setSendingStatus("pending");
      setGoNext(false);
    } else if (
      control &&
      (control.name === "shippingMethod" || control.name === "shippingCountry")
    ) {
      
      // shippingForm.findControl("useSameAddress").value = false;
      // if (control.value === "Standard") {
      //   shippingForm.findControl("useSameAddress").value = true;
      // }

      if (control.name === "shippingCountry") {
        const shippingCountryValue = control.value;
        const suburbEls = document.querySelectorAll(
          "div.ecm_suburb_col div.form-group label span:first-child"
        );
        const postcodeEls = document.querySelectorAll(
          "div.ecm_postcode_col div.form-group label span:first-child"
        );

        suburbEls.forEach((suburbEl) => {
          suburbEl.innerHTML = "Suburb";
        });

        postcodeEls.forEach((suburbEl) => {
          suburbEl.innerHTML = "Postcode";
        });

        if (shippingCountryValue === "USA") {
          suburbEls.forEach((suburbEl) => {
            suburbEl.innerHTML = "City";
          });

          postcodeEls.forEach((suburbEl) => {
            suburbEl.innerHTML = "Zip Code";
          });
        }
      }

      const value = { ...shippingForm.getValue(), cartId: env.getDevCartId() };
      post.send(env.apiBase + "/api/cart/saveShippingInfo", value);
      setSendingStatus("pending");
      setGoNext(false);
    }
    else if(control &&
      (control.name === "useSameAddress")){
        
        setTimeout(() => {
          const suburbEls = document.querySelectorAll(
            "div.ecm_suburb_col div.form-group label span:first-child"
          );
          const postcodeEls = document.querySelectorAll(
            "div.ecm_postcode_col div.form-group label span:first-child"
          );

          const shippingCountryValue = shippingForm.findControl("shippingCountry").value;

          suburbEls.forEach((suburbEl) => {
            suburbEl.innerHTML = "Suburb";
          });
  
          postcodeEls.forEach((suburbEl) => {
            suburbEl.innerHTML = "Postcode";
          });
  
          if (shippingCountryValue === "USA") {
            suburbEls.forEach((suburbEl) => {
              suburbEl.innerHTML = "City";
            });
  
            postcodeEls.forEach((suburbEl) => {
              suburbEl.innerHTML = "Zip Code";
            });
          }

        }, 500);

    }
  }

  function remove(e, index) {
    console.log(index);
    const orderItem = cart.orderItems[index];
    const value = {
      orderItemId: orderItem.orderItemId,
      cartId: env.getDevCartId(),
    };
    post.send(env.apiBase + "/api/cart/removeItem", value);
    setSendingStatus("pending");
    setGoNext(false);
  }

  function saveShipping(e) {
    //const value = { ...shippingForm.getValue(), useSameAddress: true, cartId: env.getDevCartId() };
    const value = { ...shippingForm.getValue(), cartId: env.getDevCartId() };
    //console.log("valu", value);
    post.send(env.apiBase + "/api/cart/saveShippingInfo", value);
    setSendingStatus("pending");
    setGoNext(true);
  }

  React.useEffect(() => {
    if (post.done() && sendingStatus === "pending") {
      const cart = post.response.results.cartModel;
      setSendingStatus(null);
      setCart(cart);
      if (goNext) {
        markAsFilled(currentStep);
        moveNext();
      }
    }
    // eslint-disable-next-line
  }, [post, currentStep]);

  // React.useEffect(() => {
  //   if(defaultCategory && defaultCategory === 'd7831241-6929-4780-b671-857b8af02ac9'){
  //     const _contentPanel = document.querySelector('div.app-content > div.cmsPage > section.contentPanel');
  //     if(_contentPanel){
  //       _contentPanel.classList.add("default__category__masterclass");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const stepStyle = (index) => {
    const locStyle =
      index === currentStep
        ? style.stepCurrent
        : index === currentStep - 1
        ? style.stepPrev
        : index === currentStep + 1
        ? style.stepNext
        : style.step;
    const movingStyle = index === lastStep ? style.stepLast : null;
    return [locStyle, movingStyle];
  };

  const next = () => {
    markAsFilled(currentStep);
    moveNext();
    utils.ui.scrollTo("#ecmcart");
  };

  return (
    <div css={style.cart} id="ecmcart">
      <div css={style.stepsWrapper}>
        <StepNav stepInfo={stepInfo} />
        <div
          css={style.steps}
          style={{ transform: `translateX(${-100 * currentStep}%)` }}
        >
          {steps.map((step, index) => {
            let stepView = null;
            switch (step.id) {
              case "orderItems":
                stepView = (
                  <OrderItems
                    cart={cart}
                    form={orderItemForm}
                    moveNext={next}
                    post={post}
                    remove={remove}
                  />
                );
                break;
              case "shipping":
                stepView = (
                  <Shipping
                    cart={cart}
                    form={shippingForm}
                    saveShipping={saveShipping}
                    post={post}
                  />
                );
                break;
              case "payment":
                stepView = <Pay cart={cart} post={post} />;
                break;
              default:
                break;
            }
            return (
              <div
                css={stepStyle(index)}
                key={step.id}
                className="cart__step__style"
              >
                {stepView}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function EmptyCart() {
  return (
    <div css={style.cart}>
      <div css={style.stepsWrapper}>
        <h3>Your cart is empty.</h3>
      </div>
    </div>
  );
}
