//import { css  } from '@emotion/core'
import { variables } from '../../../cssInJs'

export default {
    ecm__shipping__form : {
        'h3' : {
            color: variables.fontColor,
            fontFamily: variables.familyBase
        }
    }
}
